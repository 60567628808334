import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Notifications from "react-notify-toast";
import "react-loading-skeleton/dist/skeleton.css";
import { isLogIn } from "./utils";
const LogIn = React.lazy(() => import("./components/LogIn/LogIn"));
const SearchPage = React.lazy(() => import("./components/Search/SearchPage"));
const Questions = React.lazy(() => import("./components/Question/Questions"));

function PrivateRoute({ children }) {
  const auth = isLogIn();
  return auth ? children : <Navigate to="/" />;
}

function App() {
  return (
    <React.Fragment>
      <Notifications />
      <Suspense fallback={<div className="login-form">Loading</div>}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LogIn />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <SearchPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/questions-list"
              element={
                <PrivateRoute>
                  <Questions />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
