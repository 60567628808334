import DOMPurify from "dompurify";

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const isLogIn = () => {
  if (window.sessionStorage.getItem('token')) return true;
  return false;
}
